
import { computed, defineComponent, onMounted, ref, unref } from 'vue'
import { firestore } from '@/plugins/firebase'
import { useProfile } from '@/composables/profile/useProfile'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '../base/BaseModal.vue'
import ConnectButton from './ConnectButton.vue'
import ConnectTwitterButton from './twitter/ConnectTwitterButton.vue'
import { useAxios } from '@/composables/useAxios'

interface TwitterPage {
  profile_image_url: string;
  name: string;
  screen_name: string;
}

export default defineComponent({
  components: {
    BaseModal,
    ConnectButton,
    ConnectTwitterButton,
  },
  setup () {
    const { profile } = useProfile()

    const image = computed(() => require('@/assets/img/twitter-logo-full.png'))

    const {
      isActive: isOpen,
      setInactive: closeModal,
    } = useToggle(false)

    const {
      isActive: isConnected,
      setActive: setConnected,
      setInactive: setDisconnected,
    } = useToggle(false)

    const selectedPage = ref<TwitterPage>()

    const hasSelectedPage = computed(() => selectedPage.value !== undefined)

    const disconnect = async () => {
      const { httpFunctions } = await useAxios()
      await httpFunctions.put(`/socialLogin-requests/disconnect/${profile.value.id}/twitter`)
    }

    onMounted(async () => {
      firestore.collection('socialAccounts')
        .doc(unref(profile).id)
        .onSnapshot((accountsDoc) => {
          selectedPage.value = undefined

          if (accountsDoc.exists) {
            const accountsData = accountsDoc.data()

            if (accountsData) {
              const { twitter } = accountsData

              if (twitter) {
                setConnected()

                selectedPage.value = twitter

                return
              }
            }
          }

          setDisconnected()
          selectedPage.value = undefined
        })
    })

    return {
      closeModal,
      hasSelectedPage,
      selectedPage,
      image,
      isConnected,
      isOpen,
      disconnect,
    }
  },
})


import { defineComponent, unref } from 'vue'
import { firestore } from '@/plugins/firebase'
import { useProfile } from '@/composables/profile/useProfile'
import { useAxios } from '@/composables/useAxios'

export default defineComponent({
  emits: [
    'connected',
  ],
  setup (props, { emit }) {
    const { profile } = useProfile()

    const getLoginUrl = async (profileId: string) => {
      const { httpFunctions } = await useAxios()
      const { data } = await httpFunctions.get(`/socialLogin-requests/authorize/${profileId}/twitter`)
      return data.url
    }

    const openFacebookLoginPage = async () => {
      const loginURL = await getLoginUrl(unref(profile).id)
      const loginWindow = window.open(loginURL, '_blank')

      const unsubscribe = firestore.collection('socialAccounts')
        .doc(unref(profile).id)
        .onSnapshot((accountsDoc) => {
          const accountsData = accountsDoc.data()

          if (accountsData && 'twitter' in accountsData) {
            unsubscribe()

            loginWindow?.close()

            emit('connected')
          }
        })
    }

    return {
      openFacebookLoginPage,
    }
  }
})

<template>
  <base-modal
    hide-footer
    v-model:open="isOpen"
  >
    <template v-slot:button>
      <connect-button
        :accounts="[{
          image: image,
          connected: hasSelectedPage
        }]"
      />
    </template>

    <div class="card-header">
      <div class="card-title">
        {{ $t('page.settings.social_accounts.sentence.connect_social_account-dynamic', { source: 'Twitter' }) }}
      </div>
    </div>
    <div class="card-body">
      <div v-if="isConnected">
        <div v-if="hasSelectedPage">
          <div class="font-semibold mt-8 mb-8">
            {{ $t('page.settings.social_accounts.sentence.you_are_connected_in-dynamic', { source: 'Twitter' }) }}
          </div>

          <div class="flex flex-col items-center my-12">
            <img
              class="w-16 h-16 mb-2 rounded-full"
              :src="selectedPage?.profile_image_url"
            >
            <div class="font-semibold">
              {{ selectedPage?.name }}
            </div>
            <div class="text-xs text-gray-500">
              @{{ selectedPage?.screen_name }}
            </div>
          </div>
        </div>

        <div class="card-footer pb-0 pr-0 flex justify-end items-center space-x-2">
          <button
            @click.prevent="disconnect"
            class="btn"
          >
            {{ $t('page.settings.social_accounts.sentence.disconnect_social_account-dynamic', { source: 'Twitter' }) }}
          </button>
          <button
            class="btn btn-primary"
            @click.prevent="closeModal"
          >
            {{ $t('action.finish') }}
          </button>
        </div>
      </div>
      <div v-else>
        <div class="mt-8 mb-12">
          {{ $t('page.settings.social_accounts.sentence.connect_social_account_message-dynamic', { source: 'Twitter' }) }}
        </div>
        <div class="card-footer pb-0 pr-0 flex justify-end items-center space-x-2">
          <connect-twitter-button />
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, unref } from 'vue'
import { firestore } from '@/plugins/firebase'
import { useProfile } from '@/composables/profile/useProfile'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '../base/BaseModal.vue'
import ConnectButton from './ConnectButton.vue'
import ConnectTwitterButton from './twitter/ConnectTwitterButton.vue'
import { useAxios } from '@/composables/useAxios'

interface TwitterPage {
  profile_image_url: string;
  name: string;
  screen_name: string;
}

export default defineComponent({
  components: {
    BaseModal,
    ConnectButton,
    ConnectTwitterButton,
  },
  setup () {
    const { profile } = useProfile()

    const image = computed(() => require('@/assets/img/twitter-logo-full.png'))

    const {
      isActive: isOpen,
      setInactive: closeModal,
    } = useToggle(false)

    const {
      isActive: isConnected,
      setActive: setConnected,
      setInactive: setDisconnected,
    } = useToggle(false)

    const selectedPage = ref<TwitterPage>()

    const hasSelectedPage = computed(() => selectedPage.value !== undefined)

    const disconnect = async () => {
      const { httpFunctions } = await useAxios()
      await httpFunctions.put(`/socialLogin-requests/disconnect/${profile.value.id}/twitter`)
    }

    onMounted(async () => {
      firestore.collection('socialAccounts')
        .doc(unref(profile).id)
        .onSnapshot((accountsDoc) => {
          selectedPage.value = undefined

          if (accountsDoc.exists) {
            const accountsData = accountsDoc.data()

            if (accountsData) {
              const { twitter } = accountsData

              if (twitter) {
                setConnected()

                selectedPage.value = twitter

                return
              }
            }
          }

          setDisconnected()
          selectedPage.value = undefined
        })
    })

    return {
      closeModal,
      hasSelectedPage,
      selectedPage,
      image,
      isConnected,
      isOpen,
      disconnect,
    }
  },
})
</script>
